<template>
    <v-container fluid>
        <v-card elevation="1" :loading="loading" class="mt-3">
            <Page-Header :title="$t('groups.groups')" icon="mdi-account-group">
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        dark
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                class="pa-5"
                :headers="headers"
                :items="groups"
                :search="search"
                :items-per-page="10"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template v-slot:top>
                    <v-text-field
                        dense
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        outlined
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" persistent max-width="600px">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-card>
                                <v-toolbar color="primary darken-1" dark>
                                    <v-card-title>
                                        <span class="">{{
                                            (editedIndex === -1
                                                ? $t("add")
                                                : $t("edit")) +
                                                " " +
                                                $t("groups.group")
                                        }}</span>
                                    </v-card-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    hide-details
                                                    persistent-hint
                                                    v-model="editedItem.name"
                                                    outlined
                                                    dense
                                                    :rules="rules"
                                                    :label="
                                                        $t('groups.groupName')
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    hide-details
                                                    dense
                                                    v-model="
                                                        editedItem.description
                                                    "
                                                    outlined
                                                    :label="
                                                        $t('groups.description')
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select
                                                    hide-details
                                                    dense
                                                    v-model="groupRoles"
                                                    :label="$t('groups.roles')"
                                                    outlined
                                                    :items="filterdRoles"
                                                    chips
                                                    multiple
                                                    :menu-props="{
                                                        maxHeight: '90%'
                                                    }"
                                                    item-text="roleCaption"
                                                    item-value="id"
                                                >
                                                    <template
                                                        v-slot:item="{
                                                            item,
                                                            attrs,
                                                            on
                                                        }"
                                                    >
                                                        <v-list-item
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            #default="{ active }"
                                                        >
                                                            <v-list-item-action>
                                                                <v-checkbox
                                                                    :input-value="
                                                                        active
                                                                    "
                                                                ></v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <span>{{
                                                                        $t(
                                                                            "roles." +
                                                                                item.roleCaption
                                                                        )
                                                                    }}</span>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                    <!-- <template slot="item" slot-scope="data">
                              {{ data.active }}
                            </template> -->
                                                    <template
                                                        v-slot:selection="{
                                                            item,
                                                            index
                                                        }"
                                                    >
                                                        <v-chip
                                                            v-if="index === 0"
                                                        >
                                                            <span>
                                                                {{
                                                                    $t(
                                                                        "roles." +
                                                                            item.roleCaption
                                                                    )
                                                                }}
                                                                <!-- {{ item.roleCaption }} -->
                                                            </span>
                                                        </v-chip>
                                                        <span
                                                            v-if="index === 1"
                                                            class="grey--text text-caption"
                                                        >
                                                            (+{{
                                                                groupRoles.length -
                                                                    1 +
                                                                    " " +
                                                                    $t(
                                                                        "others"
                                                                    )
                                                            }})
                                                        </span>
                                                    </template>
                                                    <template
                                                        v-slot:prepend-item
                                                    >
                                                        <v-list-item>
                                                            <!-- <v-list-item-action>
                                  <v-icon
                                    :color="
                                      editedItem.groupRoles.length > 0
                                        ? 'indigo darken-4'
                                        : ''
                                    "
                                  >
                                    {{ icon }}
                                  </v-icon>
                                </v-list-item-action> -->
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-text-field
                                                                        v-model="
                                                                            searchRole
                                                                        "
                                                                        outlined
                                                                        dense
                                                                        hide-details
                                                                        :label="
                                                                            $t(
                                                                                'search'
                                                                            )
                                                                        "
                                                                    ></v-text-field>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider></v-divider>
                                                        <v-list-item
                                                            ripple
                                                            @click="toggle"
                                                        >
                                                            <v-list-item-action>
                                                                <v-icon
                                                                    :color="
                                                                        groupRoles.length >
                                                                        0
                                                                            ? 'indigo darken-4'
                                                                            : ''
                                                                    "
                                                                >
                                                                    {{ icon }}
                                                                </v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{
                                                                        $t(
                                                                            "selectAll"
                                                                        )
                                                                    }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider
                                                            class="mt-2"
                                                        ></v-divider>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="primary darken-1"
                                        class="white--text"
                                        :loading="loading"
                                        @click="save"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :loading="loading"
                                        text
                                        color="red"
                                        @click="close"
                                    >
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                    ></confirm-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="text-end">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    @click="editItem(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    :loading="loading"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span> {{ $t("edit") }} </span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="red darken-2"
                                    icon
                                    :loading="loading"
                                    @click="deleteItem(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>
                                        mdi-delete-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span> {{ $t("delete") }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            searchRole: "",
            headers: [
                { text: this.$t("groups.groupName"), value: "name" },
                {
                    text: this.$t("groups.description"),
                    value: "description"
                },
                { text: "", value: "actions" }
            ],
            groups: [],
            roles: [],
            editedItem: {
                name: "",
                description: "",
                groupRoles: []
            },
            groupRoles: [],

            defaultItem: {
                name: "",
                description: "",
                groupRoles: []
            },

            rules: [value => !!value || "Required."]
        };
    },
    created() {
        this.loading = true;
        this.getData();
        axios.get("Accounts/GetRoles").then(response => {
            this.roles = response.data.data;
        });
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    computed: {
        likesAllFruit() {
            return this.groupRoles.length === this.roles.length;
        },
        likesSomeFruit() {
            return this.groupRoles.length > 0 && !this.likesAllFruit;
        },
        icon() {
            // if (this.editedItem.groupRoles.length === this.roles.length)
            //   return "mdi-close-box";
            // else if (this.editedItem.groupRoles.length > 0) return "mdi-minus-box";
            // else return "mdi-checkbox-blank-outline";

            if (this.likesAllFruit) return "mdi-close-box";
            if (this.likesSomeFruit) return "mdi-minus-box";
            return "mdi-checkbox-blank-outline";
        },
        filterdRoles() {
            var roles = Object.assign([], this.roles);
            if (this.searchRole) {
                return roles.filter(item => {
                    return this.searchRole
                        .toLowerCase()
                        .split(" ")
                        .every(v => item.roleCaption.toLowerCase().includes(v));
                });
            } else {
                return roles;
            }
        }
    },
    methods: {
        toggle() {
            this.$nextTick(() => {
                if (this.likesAllFruit == true) {
                    this.groupRoles = [];
                } else {
                    this.groupRoles = this.roles.map(obj => obj.id);
                }
            });
        },
        getText(i) {
            var result = this.groups.filter(obj => {
                return obj.value == i;
            });
            return result[0].text;
        },
        editItem(item) {
            this.editedIndex = this.groups.indexOf(item);
            this.editedItem = Object.assign({}, item);

            var roles = [];
            if (item.applicationRoles) {
                for (var i = 0; i < item.applicationRoles.length; i++) {
                    roles.push(item.applicationRoles[i].applicationRoleId);
                }
            }

            this.groupRoles = roles;
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.groups.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.groups[this.editedIndex];

            axios
                .delete("Accounts/DeleteGroup?id=" + deleteItem.id)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }

                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.getData();

                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            try {
                this.loading = true;
                axios
                    .get("Accounts/GetGroups")
                    .then(response => {
                        this.groups = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        save() {
            var val = this.$refs.form.validate();
            if (val) {
                if (this.editedIndex > -1) {
                    var updatedItem = this.groups[this.editedIndex];
                    updatedItem.name = this.editedItem.name;
                    updatedItem.description = this.editedItem.description;
                    updatedItem.groupRoles = this.groupRoles;

                    axios
                        .post("Accounts/UpdateGroup", updatedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.close();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }

                            this.getData();
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.getData();
                            console.log(e);
                        });
                    Object.assign(
                        this.groups[this.editedIndex],
                        this.editedItem
                    );
                } else {
                    this.editedItem.groupRoles = this.groupRoles;
                    axios
                        .post("Accounts/AddGroup", this.editedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.close();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }

                            this.getData();
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.getData();
                            console.log(e);
                        });
                }
            }
        }
    }
};
</script>
